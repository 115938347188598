@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin-right: auto;
  margin-left: auto;
  max-width: 1550px;
  background-color: #f0efee;
}

@media (max-width: 767px) {
  body {
    margin: 0;
  }
}

@font-face {
  font-family: "Optum-Sans";
  src: url("../public/fonts/OptumSans/OptumSans-Regular.woff2") format("woff2");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Optum-Sans-Bold";
  src: url("../public/fonts/OptumSans/OptumSans-Bold.woff2") format("woff2");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Optum-Sans-Extra-Bold";
  src: url("../public/fonts/OptumSans/OptumSans-XBold.woff2") format("woff2");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Lato";
  src: url("../public/fonts/Lato/Lato-Regular.ttf") format("ttf");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Lato-Bold";
  src: url("../public/fonts/Lato/Lato-Bold.ttf") format("ttf");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
